/**
 * Block Hero
 */
 'use strict';

// Hero Slider
const initHeroSlider = () => {
  let banner = document.querySelector('.hero-carousel.glide');
  if (banner.classList.contains('glide')) {
    let glide = new Glide('.glide', {
      animationDuration: 1000,
      type: 'carousel',
      gap: 0,
      animationTimingFunc: 'ease-out'
    })
    glide.mount()
  }
}


/* Scroll to Next Section */
class skipSection {
  constructor(selector, element) {
    this.selector = document.querySelector(selector)
    this.element = this.selector.querySelector(element)
  }
  // Action method
  init() {
    this.scroll = this.scroll.bind(this)
    if(this.element) {
      this.element.addEventListener('click', this.scroll)
    }
  }
  // Scroll method
  scroll(e) {
    e.preventDefault()
    window.scrollTo({
      top: this.selector.offsetHeight,
      behavior: 'smooth'
    })
  }
}

new skipSection('.block-hero', '.scroll').init()
initHeroSlider()